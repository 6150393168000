import React from 'react';

const NotInOrganization = () => {
    return (
        <div>
            <h1>Thanks for signing up!</h1>
            <p>You are not currently part of any organization. Please contact your administrator to be added.</p>
        </div>
    );
};

export default NotInOrganization;
