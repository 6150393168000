import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Button, Modal, ListGroup, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ToastError from "../../shared/elements/ToastError";

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + "...";
};

const ScriptList = () => {
    const authContext = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [scripts, setScripts] = useState([]); // Initialize as an empty array
    const [showModal, setShowModal] = useState(false);
    const [selectedScript, setSelectedScript] = useState(null);
    const navigate = useNavigate();

    const fetchScripts = useCallback(async () => {
        try {
            const response = await sendRequest(
                "/api/scripts",
                "GET",
                null,
                {
                    Authorization: "Bearer " + authContext.token,
                }
            );
            const responseData = response.data; // Access the data property

            if (Array.isArray(responseData)) {
                setScripts(responseData);
            } else {
                console.error('Response data is not an array:', responseData); // Debug log
                setScripts([]);
            }
        } catch (err) {
            console.error('Error fetching scripts:', err); // Debug log
        }
    }, [sendRequest, authContext.token]);

    const handleDeleteScript = async () => {
        if (!selectedScript) return;
        try {
            await sendRequest(
                `/api/scripts/${selectedScript.id}`,
                "DELETE",
                null,
                {
                    Authorization: "Bearer " + authContext.token,
                }
            );
            setScripts((prevScripts) => prevScripts.filter(script => script.id !== selectedScript.id));
            setShowModal(false);
            setSelectedScript(null);
        } catch (err) {
            console.error('Error deleting script:', err); // Debug log
        }
    };

    useEffect(() => {
        fetchScripts();
    }, [fetchScripts]);

    const handleShowScript = async (script) => {
        try {
            const response = await sendRequest(
                `/api/scripts/${script.id}`,
                "GET",
                null,
                {
                    Authorization: "Bearer " + authContext.token,
                }
            );
            setSelectedScript(response.data);
            console.log('Selected script:', response.data); // Debug log
            setShowModal(true);
        } catch (err) {
            console.error('Error showing script:', err); // Debug log
        }
    };

    const handleEditScript = () => {
        if (selectedScript) {
            navigate(`/scripts/edit/${selectedScript.id}`);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedScript(null);
    };

    return (
        <div>
            <ToastError show={!!error} message={error} onClose={clearError} />
            <Button variant="primary" onClick={() => navigate('/scripts/new')} style={{ marginBottom: '20px' }}>
                Add Script
            </Button>
            {isLoading && <Spinner animation="border" />}
            <ListGroup>
                {scripts.length === 0 && <ListGroup.Item>No scripts found.</ListGroup.Item>}
                {scripts.map((script) => (
                    <ListGroup.Item key={script.id} onClick={() => handleShowScript(script)}>
                        {script.title} {/* Assuming title is the correct property */}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Script Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedScript && (
                        <div>
                            <p><strong>Title:</strong> {selectedScript.title}</p>
                            <p><strong>Content:</strong> {truncateText(selectedScript.content, 50)}</p>
                            {selectedScript.campaign && (
                                <p><strong>Campaign:</strong> {selectedScript.campaign.name}</p>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleDeleteScript()}>
                        Delete Script
                    </Button>
                    <Button variant="primary" onClick={handleEditScript}>
                        Edit Script
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ScriptList;
