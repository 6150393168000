import React from "react";
import Metrics from "../components/Metrics";

const Dashboard = (props) => {
	return (
		<div>
			<Metrics />
		</div>
	);
};

export default Dashboard;
