import React, { useEffect, useState, useContext, useCallback } from "react";

import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Button } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";

import UpdateUser from "../components/UpdateUser";
import Me from "../components/Me";
import OrganizationSelector from "../components/OrganizationSelector";

const Settings = () => {
	const { error, sendRequest, clearError } = useHttpClient();
	const [loadedUserInfo, setLoadedUserInfo] = useState();
	const auth = useContext(AuthContext);

	const fetchUserData = useCallback(async () => {
		try {
			const responseData = await sendRequest(
				"/api/user",
				"GET",
				null,
				{},
				auth.token
			);
			setLoadedUserInfo(responseData.data);
		} catch (err) {

		}
	}, [sendRequest, auth.token]);

	useEffect(() => {
		fetchUserData();
	}, [fetchUserData]);

	return (
		<React.Fragment>
			<ToastError show={!!error} message={error} onClose={clearError} />
			<Me loadedUserInfo={loadedUserInfo} />
			<UpdateUser onUserUpdated={fetchUserData} />
			<OrganizationSelector />
			<Button variant="danger" className="mx-auto d-block" style={{width: "18rem"}} onClick={auth.logout} href="/">Logout</Button>
		</React.Fragment>
	);
};

export default Settings;
