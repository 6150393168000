import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import {
	Button,
	Modal,
	ListGroup,
	Spinner,
	ToggleButtonGroup,
	ToggleButton,
	Offcanvas
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ToastError from "../../shared/elements/ToastError";
import VideoCard from "./VideoCard";
import ReactPlayer from "react-player";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaList, FaTh, FaDownload, /*FaShareAlt, FaFacebook, FaLinkedin, FaInstagram*/ } from 'react-icons/fa';


const truncateText = (text, maxLength) => {
	if (text.length <= maxLength) {
		return text;
	}
	return text.substring(0, maxLength) + "...";
};




const VideoList = () => {
	const authContext = useContext(AuthContext);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const [videos, setVideos] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [view, setView] = useState("cards");
	const [showFinalizeModal, setShowFinalizeModal] = useState(false);
	//const [showShareOptions, setShowShareOptions] = useState(false);

	const [showOffcanvas, setShowOffcanvas] = useState(false);
	const handleClose = () => setShowOffcanvas(false);
	const handleShow = () => setShowOffcanvas(true);
	
	/*const handleShareClick = () => {
        setShowShareOptions(!showShareOptions);
    };*/

	const renderStatus = (status, videoUrl) => {

		let statusElement;
	
		if (status === "pending") {
			statusElement = <Spinner animation="border" size="sm" variant="warning" />;
		} else if (status === "processing") {
			statusElement = <Spinner animation="border" size="sm" variant="success" />;
		} else if (status === "completed") {
			statusElement = (
				<div style={{ display: "flex", alignItems: "center" }}>
					<Button
						variant="link"
						style={{ padding: "5px", borderRadius: "50%" }}
						href={videoUrl}
						target="_blank"
						rel="noopener noreferrer"
						download
					>
						<FaDownload />
					</Button>
			</div>
			);
		} else if (status === "failed") {
			statusElement = (
				<span style={{ display: "inline-block", width: "10px", height: "10px", backgroundColor: "red", borderRadius: "50%" }}></span>
			);
		}
	
		return (
			<OverlayTrigger
				placement="top"
				delay={{ show: 250, hide: 400 }}
				overlay={<Tooltip>{status === "completed" ? "Download" : status}</Tooltip>}
			>
				<div style={{ display: "inline-block" }}>{statusElement}</div>
			</OverlayTrigger>
		);
	};

	const navigate = useNavigate();

	const handleFinalizeVideo = async () => {
		if (!selectedVideo) return;
		try {
			console.log(selectedVideo.orgName);
			await sendRequest(
				`/api/videos/${selectedVideo.id}/finalize`,
				"POST",
				JSON.stringify({ orgName: selectedVideo.orgName }),
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			setShowFinalizeModal(false);
			setShowModal(false);
			fetchVideos(); // Refresh the video list after finalization
		} catch (err) {
			console.error("Error finalizing video:", err);
		}
	};

	const handleDeleteVideo = async () => {
		if (!selectedVideo) return;
		try {
			await sendRequest(
				`/api/videos/${selectedVideo.id}`,
				"DELETE",
				JSON.stringify({ orgName: selectedVideo.orgName }),
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			setShowModal(false);
			fetchVideos(); // Refresh the video list after finalization
		} catch (err) {
			console.error("Error deleting video:", err);
		}
	};

	const fetchVideos = useCallback(async () => {
		try {
			const response = await sendRequest("/api/videos", "GET", null, {
				Authorization: "Bearer " + authContext.token,
			});
			const responseData = response.data;
			if (Array.isArray(responseData)) {
				setVideos(responseData);
			} else {
				setVideos([]);
			}
		} catch (err) {
			console.error("Error fetching videos:", err);
		}
	}, [sendRequest, authContext.token]);

	useEffect(() => {
		fetchVideos();
	}, [fetchVideos]);

	const handleShowVideo = async (video) => {
		try {
			const response = await sendRequest(
				`/api/videos/${video.id}/details`,
				"GET",
				null,
				{
					Authorization: "Bearer " + authContext.token,
				}
			);
			setSelectedVideo(response.data);
			setShowModal(true);
		} catch (err) {
			console.error("Error fetching video details:", err);
		}
	};

	/*const handleEditVideo = () => {
		if (selectedVideo) {
			navigate(`/videos/edit/${selectedVideo.id}`);
		}
	};*/

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedVideo(null);
	};

	const handleViewChange = (val) => setView(val);

	return (
		<div>
			<ToastError show={!!error} message={error} onClose={clearError} />
			<Button
				variant="primary"
				onClick={() => navigate("/videos/new")}
				style={{ marginBottom: "20px", marginRight: "20px" }}
			>
				Add Video
			</Button>
			<ToggleButtonGroup
				type="radio"
				name="view"
				value={view}
				onChange={handleViewChange}
				style={{ marginBottom: "20px" }}
			>
				<ToggleButton id="tbg-btn-1" value={"list"}>
					<FaList />
				</ToggleButton>
				<ToggleButton id="tbg-btn-2" value={"cards"}>
					<FaTh />
				</ToggleButton>
			</ToggleButtonGroup>
			<Button variant="light" style={{ marginLeft: "20px",marginBottom: "20px" }} onClick={handleShow}>Important Note!</Button>
			{isLoading && <Spinner animation="border" />}
			{view === "list" ? (
				<ListGroup>
					{videos.length === 0 && (
						<ListGroup.Item>No videos found.</ListGroup.Item>
					)}
					{videos.map((video) => (
						<ListGroup.Item
							key={video.id}
							onClick={() => handleShowVideo(video)}
						>
							{video.title}
						</ListGroup.Item>
					))}
				</ListGroup>
			) : (
				<div style={{ display: "flex", flexWrap: "wrap" }}>
					{videos.length === 0 && <p>No videos found.</p>}
					{videos.map((video) => (
						<VideoCard
							key={video.id}
							video={video}
							onShowVideo={handleShowVideo}
						/>
					))}
				</div>
			)}
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedVideo ? selectedVideo.title : "Video Details"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{selectedVideo && (
						<div>
							{selectedVideo.video_url ? (
								<ReactPlayer
									url={selectedVideo.video_url}
									controls={true}
									width="100%"
								/>
							) : (
								<img
									src={selectedVideo.thumbnail_url}
									width="100%"
									alt={selectedVideo.avatar.avatar_name}
								/>
							)}
							<p>
								{renderStatus(selectedVideo.status, selectedVideo.video_url)}
							</p>
							{selectedVideo.script ? (
								<div>
									<p>
										<strong>Script:</strong> {selectedVideo.script.title}
									</p>
									<p>
										<strong>Script Content:</strong>{" "}
										{truncateText(selectedVideo.script.content, 100)}
									</p>
								</div>
							) : <p>
							<strong>Script:</strong> No longer exists
						</p>}
							<p>
								<strong>Avatar:</strong> {selectedVideo.avatar.avatar_name}
							</p>
							{selectedVideo.campaign && (
								<p>
									<strong>Campaign:</strong> {selectedVideo.campaign.name}
								</p>
							)}
							<p>
								<strong>Created By: </strong> {selectedVideo.createdBy}
							</p>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Close
					</Button>
					{/*<Button variant="primary" onClick={handleEditVideo}>
						Edit Video
					</Button>*/}
					{(authContext.isSuperAdmin || authContext.orgRole === "orgAdmin") && (
						<React.Fragment>
							<Button
								variant="success"
								onClick={() => setShowFinalizeModal(true)}
							>
								Finalize Video
							</Button>
							<Button variant="danger" onClick={() => handleDeleteVideo()}>
								Delete Video
							</Button>
						</React.Fragment>
					)}
				</Modal.Footer>
			</Modal>
			<Modal
				show={showFinalizeModal}
				onHide={() => setShowFinalizeModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Confirm Finalization</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to finalize this video? This action cannot be
					undone.
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => setShowFinalizeModal(false)}
					>
						Cancel
					</Button>
					<Button variant="success" onClick={handleFinalizeVideo}>
						Finalize
					</Button>
				</Modal.Footer>
			</Modal>
			<Offcanvas show={showOffcanvas} onHide={handleClose} placement={"end"}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title><span class="icon">👋</span>Hello!</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<p>
						Please note that generating videos will take some time. Make sure to
						refresh the page to see the status of your video.
					</p>
					<p>
						Due to how videos are made with the API, we recommend them for internal
						use only. If you would like to make a video for commercial purposes, like
						advertising or social media, contact us!
					</p>
					<p>
						<strong>Please contact Max or Kaleb to to create a professional video for you!
						</strong>
					</p>
					<p>
						<ul><strong>Max: </strong>
						<a href="mailto:maximus@ratva.com">maximus@ratva.com</a></ul>
						<ul><strong>Kaleb: </strong>
						<a href="mailto:kaleb@ratava.com">kaleb@ratava.com</a></ul>
					</p>
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
};

export default VideoList;
